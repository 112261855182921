/* You can add global styles to this file, and also import other style files */
// @import 'node_modules/ng-zorro-antd/ng-zorro-antd.variable.min.css';
// @import 'node_modules/ng-zorro-antd/ng-zorro-antd.dark.min.css';
// @import 'node_modules/ng-zorro-antd/ng-zorro-antd.min.css';
@import '../node_modules/ng-zorro-antd/input/style/index.min.css';
@import '../node_modules/ng-zorro-antd/button/style/index.min.css';
@import '../node_modules/ng-zorro-antd/form/style/index.min.css';
@import '../node_modules/ng-zorro-antd/menu/style/index.min.css';
@import '../node_modules/ng-zorro-antd/src/style/index.min.css';
@import '../node_modules/ng-zorro-antd/select/style/index.min.css';
@import '../node_modules/ng-zorro-antd/date-picker/style/index.min.css';
@import '../node_modules/ng-zorro-antd/steps/style/index.min.css';
@import '../node_modules/ng-zorro-antd/checkbox/style/index.min.css';
@import '../node_modules/ng-zorro-antd/collapse/style/index.min.css';
@import '../node_modules/ng-zorro-antd/cascader/style/index.min.css';
@import '../node_modules/ng-zorro-antd/upload/style/index.min.css';
@import '../node_modules/ng-zorro-antd/message/style/index.min.css';
@import '../node_modules/ng-zorro-antd/modal/style/index.min.css';
@import '../node_modules/ng-zorro-antd/layout/style/index.min.css';
@import '../node_modules/ng-zorro-antd/empty/style/index.min.css';
@import '../node_modules/ng-zorro-antd/card/style/index.min.css';
@import '../node_modules/ng-zorro-antd/radio/style/index.min.css';
@import '../node_modules/ng-zorro-antd/popconfirm/style/index.min.css';
@import '../node_modules/ng-zorro-antd/slider/style/index.min.css';
@import '../node_modules/ng-zorro-antd/table/style/index.min.css';

// @import '../node_modules/ng-zorro-antd/tabs/style/index.min.css';

// @import '../node_modules/ng-zorro-antd/list/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/space/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/affix/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/alert/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/anchor/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/auto-complete/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/avatar/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/back-top/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/badge/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/breadcrumb/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/button/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/calendar/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/carousel/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/code-editor/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/collapse/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/comment/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/cron-expression/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/descriptions/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/divider/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/drawer/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/dropdown/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/esm2022/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/experimental/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/fesm2022/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/divider/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/graph/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/grid/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/i18n/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/icon/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/image/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/input/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/input-number/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/mention/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/menu/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/notification/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/page-header/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/pagination/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/pipes/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/popover/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/progress/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/qr-code/style/index.min.css';

// @import '../node_modules/ng-zorro-antd/rate/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/resizable/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/result/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/schemantics/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/segmented/style/index.min.css';

// @import '../node_modules/ng-zorro-antd/skeleton/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/space/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/spin/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/statistic/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/style/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/switch/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/tag/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/time-picker/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/timeline/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/tooltip/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/transfer/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/tree/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/tree-view/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/typography/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/version/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/water-mark/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/icon/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/dropdown/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/grid/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/modal/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/tooltip/style/index.min.css';
// @import '../node_modules/ng-zorro-antd/icon/style/index.min.css';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: aliceblue;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 3px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.1);
}

.ant-popover-message-title {
  font-size: 15px;
}
